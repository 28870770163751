// Header.js

import { FaTwitter } from "react-icons/fa";

function Header() {
  return (
    <header className="z-10 flex items-center justify-between border-b-4 border-gray-900 bg-black p-2 px-4 text-white ">
      <div className="flex flex-col items-center ">
        <a href="/" className="flex-shrink-0 ">
          <img
            src="/unDEDWink.png"
            alt="DOT is $unDED"
            className="h-16 w-16 sm:h-20 sm:w-20 md:h-36 md:w-36"
          />
        </a>
        <nav className="mt-2 flex items-baseline space-x-4 sm:ml-6 sm:mt-0"></nav>
      </div>
      <div className="flex flex-col space-y-2 sm:mt-0 sm:flex-row sm:space-x-2 sm:space-y-0">
        <div className="flex space-x-2 md:hidden">
          <a
            href="https://twitter.com/DOTISUNDED"
            target="_blank"
            rel="noopener noreferrer"
            className="flex items-center gap-2 rounded-full border-2 border-pink-500 bg-transparent p-2 text-pink-700 hover:border-transparent hover:bg-pink-500 hover:text-white"
          >
            <span className="font-semibold ">$unDED TWITTER</span>
            <FaTwitter className="h-6 w-6" />
          </a>
        </div>

        <a
          href="https://twitter.com/DOTISUNDED"
          target="_blank"
          rel="noopener noreferrer"
          className="hidden rounded-full border-4 border-pink-500 bg-transparent px-2 py-1 text-center text-sm font-semibold text-pink-700 hover:border-transparent hover:bg-pink-500 hover:text-white sm:px-4 sm:py-2 sm:text-base md:block"
        >
          JOIN $unDED TWITTER
        </a>
      </div>
    </header>
  );
}

export default Header;
