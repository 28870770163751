import AdditionalContent from "./AdditionalContent";

function MainContent() {
  return (
    <main className="relative flex-1 overflow-y-scroll  bg-unded-pattern bg-cover bg-fixed px-4 font-unbounded sm:p-4">
      <div className="relative z-10 flex w-full flex-grow flex-col items-center justify-around gap-10 py-3 text-white lg:h-auto lg:justify-center">
        <div className="flex max-lg:flex-grow max-lg:flex-col max-lg:justify-around lg:gap-40">
          <div className="flex flex-col gap-6 rounded-lg bg-gray-900 bg-opacity-75 p-4 backdrop-blur-lg sm:gap-10 sm:p-6">
            <div className="flex flex-col">
              <span className="mb-4 text-4xl font-bold uppercase max-lg:mb-2 max-lg:text-3xl">
                Dot community got <span className="text-[#FF007F]">rugged</span>
              </span>
              <span className="text-4xl font-bold uppercase max-lg:mb-2 max-lg:text-3xl">
                by {""}
                <span className="text-red-600">$ded</span>
              </span>
            </div>
            <span className="mb-4 text-4xl font-bold uppercase max-lg:mb-2 max-lg:text-3xl">
              <span className="text-green-800">100% fair launch</span> turned
              <span className="text-red-600"> scam</span>
            </span>
            <div className="flex flex-col">
              <span className="mb-4 text-4xl font-bold uppercase max-lg:mb-2 max-lg:text-3xl">
                But the <span className="text-[#FF007F]"> community</span> had
                other ideas
              </span>
              <span className="text-4xl font-bold uppercase max-lg:mb-2 max-lg:text-3xl">
                with an <span className="text-[#FF007F]"> actual</span>{" "}
                <span className="text-green-800">100% fair launch</span>
              </span>
            </div>
            <span className="mb-4 text-4xl font-bold uppercase max-lg:mb-2 max-lg:text-3xl">
              Rising out of this grave we are{" "}
              <span className="text-green-800">$unDED</span>
            </span>
          </div>
          <div className="flex flex-col self-center max-lg:w-full lg:gap-3">
            <a
              href="https://app.beamswap.io/exchange/braindex?inputCurrency=glmr&outputCurrency=0x1adbd8568d5f169295c18f91f9970d007888ade1"
              target="_blank"
              rel="noopener noreferrer"
              className="inline-block w-full rounded-full border-4 border-black bg-pink-500 px-10 py-4 text-center text-xl font-black text-white hover:bg-pink-700 hover:text-white sm:px-20 sm:text-4xl  lg:py-10"
            >
              BUY $unDED
            </a>

            <div className="mt-3 break-words rounded-lg bg-gray-900 bg-opacity-75 p-1 text-center text-sm">
              <p>Buy soon on MoonBeam with $GLMR!</p>
              <p className="break-all">
                CA: 0x1adbd8568d5f169295c18f91f9970d007888ade1
              </p>
            </div>
          </div>
        </div>
        <div className="w-full rounded-lg bg-pink-500 bg-opacity-75 p-6 backdrop-blur-lg lg:block">
          <AdditionalContent></AdditionalContent>
        </div>
      </div>
    </main>
  );
}

export default MainContent;
