function AdditionalContent() {
  return (
    <div className="font-unbounded">
      <h1 className="pb-6 lg:text-4xl">THE MEME TOKEN YOU DESERVE</h1>
      <div className="grid grid-cols-1 gap-4 pb-3 text-center sm:grid-cols-2 lg:grid-cols-4">
        <span className="sm:border-r sm:border-gray-200 sm:pr-4">
          $unDED rises on Polkadot, rectifying the $DED fiasco that drained
          $1.5m from its treasury.
        </span>
        <span className="sm:border-r sm:border-gray-200 sm:pr-4">
          Betrayal by $DED ends here: no insider allocations, 100% power to the
          community.
        </span>
        <span className="sm:border-r sm:border-gray-200 sm:pr-4">
          We're the vanguard against Polkadot's manipulation, bringing control
          back to its rightful owners.
        </span>
        <span className="">
          For the community, by the community: $unDED. 100% Fair Launch
        </span>
      </div>
    </div>
  );
}

export default AdditionalContent;
